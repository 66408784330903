import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Row,
  Col,
  Card,
  ListGroup,
  Table,
  Badge,
  OverlayTrigger,
  Tooltip,
  Modal,
  Spinner,
  Form,
  Accordion,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { filterByField, listRegisters, listDonations } from "./services/api";
import { Paginate } from "../components/Paginate";
import { format } from "date-fns";

export default function Donations() {
  const params = useParams();
  const navigate = useNavigate();
  const [donations, setDonations] = useState([]);
  const [channel, setChannel] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    textContent: "a",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState({ pageNumber: 1, data: [] });
  const itemsPerPage = 20;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function loadRegisters() {
    const registers = await listRegisters();

    return registers;
  }

  async function loadChannels() {
    if (params.channelId === undefined) {
      return;
    }

    const channels = await filterByField(
      "channels",
      "channelId",
      params.channelId
    );

    setChannel(channels);
  }

  async function loadDonations() {
    setIsLoading(true);
    const registers = await loadRegisters();
    let donations;
    if (params.registerId) {
      donations = await filterByField(
        "donations",
        "registerId",
        params.registerId
      );
    }
    if (params.channelId) {
      donations = await filterByField(
        "donations",
        "channelId",
        params.channelId
      );
    }
    if (!params.channelId && !params.registerId) {
      donations = await listDonations();
    }
    const sortedDonations = donations.map((item) => {
      if (item.value.includes(".00")) {
        item.value = Math.trunc(item.value).toString();
      }
      console.log(item);
      return item;
    });

    sortedDonations.sort((a, b) => {
      if (a.created_at > b.created_at) {
        return -1;
      }
      if (a.created_at < b.created_at) {
        return 1;
      }

      return 0;
    });

    const donationWithName = sortedDonations.map((donation) => {
      return {
        ...donation,
        register: registers.filter(
          (register) => register.registerId === donation.registerId
        ),
      };
    });

    setDonations(donationWithName);
    loadDashboardData([donationWithName]);
    setIsLoading(false);
  }

  async function loadDashboardData([donations]) {
    const totalDonationValue = sumCalculator([donations]);

    const detailDonationCompleted = donationTypeTotal([donations]);

    const totalDonationPerCode = loadDonationPerCode([donations]);

    const dataCardCode = totalDonationPerCode.map((code) => {
      if (code.key === "BRD23NTL") {
        return {
          key: "One time",
          value: code.value.total,
          description: code.key,
        };
      } else {
        return {
          key: "Recurrent",
          value: code.value.total,
          description: code.key,
        };
      }
    });

    const dashboardData = [
      {
        title: "Donation total",
        total: totalDonationValue.total,
        description: "All donation sum",
        details: dataCardCode,
      },
      {
        title: "Completed Donation Total",
        total: totalDonationValue.completed,
        description: "Completed Donation",
        details: detailDonationCompleted,
      },
    ];

    setDashboardData(dashboardData);
  }

  function loadDonationPerCode([donations]) {
    const codes = [];
    donations.forEach((element) => {
      if (!codes.includes(element.motivationCode)) {
        codes.push(element.motivationCode);
      }
      return;
    });

    const donationsPerCodes = codes.map((item) => {
      const filteredDonations = donations.filter(
        (element) => element.motivationCode === item
      );
      const totalDonation = sumCalculator([filteredDonations]);

      return {
        key: item,
        value: totalDonation,
      };
    });
    return donationsPerCodes;
  }

  function sumCalculator([value]) {
    let totalCompleted = 0;
    let totalCanceled = 0;
    let PaymentMethodSelected = 0;

    value.forEach((element) => {
      if (element.donationStatus === "Completed") {
        let currentNumber = parseInt(element.value);
        totalCompleted = currentNumber + totalCompleted;
      }
      if (element.donationStatus === "PAYMENT_METHOD_SELECTED") {
        let currentNumber = parseInt(element.value);
        PaymentMethodSelected = currentNumber + PaymentMethodSelected;
      }
      if (element.donationStatus === "canceled") {
        let currentNumber = parseInt(element.value);
        totalCanceled = currentNumber + totalCanceled;
      }
      if (element.donationStatus === "pending") {
        element.donationStatus = "Created";
      }
    });
    return {
      completed: totalCompleted,
      canceled: totalCanceled,
      PaymentMethodSelected: PaymentMethodSelected,
      total: totalCompleted + PaymentMethodSelected + totalCanceled,
    };
  }

  function donationTypeTotal([donations]) {
    const donationTypes = [];
    donations.forEach((element) => {
      if (
        !donationTypes.includes(element.donationType) &&
        element.donationType
      ) {
        donationTypes.push(element.donationType);
      }
      return;
    });
    // FILTER ONLY THE TYPES
    const filteredDonation = donations.filter(
      (element) => element.donationType
    );

    // EXCLUDES TYPES WITH UNDEFINED VALUES
    const donationTypesTotal = donationTypes.map((types) => {
      const uniqueType = filteredDonation.filter(
        (obj) => obj.donationType === types
      );

      let total = sumCalculator([uniqueType]);

      return {
        key: types,
        value: total.completed,
      };
    });

    return donationTypesTotal;
  }

  async function checkStatus(donation) {
    console.log(donation.donationId);

    await axios.get(
      `https://paodiario.org/wp-content/themes/paodiario/elementor/widgets/donations/callbackPaghiper.php?order_id=${donation.donationId}`
    );
    loadDonations();
  }

  const renderTooltip = (props) => <Tooltip>{props}</Tooltip>;
  useEffect(() => {
    loadChannels();
    loadDonations();
  }, []);
  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        className="mt-4 d-flex"
        variant="secondary"
      >
        Back
      </Button>

      {/* <Row className="container py-4">
        <Col className="row row-cols-auto grid gap-3">
          {dashboardData.map((item, title) => (
            <React.Fragment key={title}>
              <Card className="dashboard-card">
                <Card.Header className="card-title">{item.title}</Card.Header>
                <Card.Body>
                  <Card.Title className="card-value">
                    R$ {item.total}
                  </Card.Title>
                  {item.details &&
                    item.details.map((element, key) => (
                      <React.Fragment key={key}>
                        <ListGroup className="border-top align-middle list-group-flush">
                          <ListGroup.Item className="d-flex justify-content-between">
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(element.description)}
                            >
                              <Badge bg="primary">{element.key}</Badge>
                            </OverlayTrigger>{" "}
                            <span>{"-"}</span>
                            <span>R$ {element.value}</span>
                          </ListGroup.Item>
                        </ListGroup>
                      </React.Fragment>
                    ))}
                  <Card.Text className="border-top card-description">
                    {item.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </React.Fragment>
          ))}
        </Col>
      </Row> */}
      <Card className="mt-3">
        <Card.Header className="text-center pt-3">
          <Paginate
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            items={donations}
            pagedDataUpdated={(newData) => {
              setCurrentPage(newData);
            }}
          />
          {channel.length > 0 ? (
            <Card.Title>
              Donations from{" "}
              <span bg="primary" className="fw-bold">
                {channel[0].name}
              </span>
            </Card.Title>
          ) : (
            <Card.Title>All Donations</Card.Title>
          )}
        </Card.Header>
        <Card.Body className="text-center">
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden align-center">Loading...</span>
            </Spinner>
          ) : (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Donation Type</th>
                  <th>Motivation Code</th>
                  <th>Payment Method</th>
                  <th>Value(R$)</th>
                  <th>Date</th>
                  <th> </th>
                  <th> </th>
                </tr>
              </thead>
              {currentPage.data.map((item, donationId) => (
                <React.Fragment key={item.donationId}>
                  <tbody>
                    <tr>
                      <td>{item.register[0].name}</td>
                      <td>{item.email}</td>
                      <td>
                        <Badge
                          pill
                          bg={
                            item.donationStatus === "Completed"
                              ? "success"
                              : item.donationStatus === "canceled"
                              ? "danger"
                              : "warning"
                          }
                        >
                          {item.donationStatus}
                        </Badge>
                      </td>
                      <td>{item.donationType}</td>
                      <td>{item.motivationCode}</td>
                      <td>{item.paymentMethod}</td>
                      <td>R$ {item.value}</td>
                      <td>
                        {format(new Date(item.created_at), "HH:mm - d/M/Y")}
                      </td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={() => {
                            setModalContent({
                              title: `donationInfo: ${item.donationId}`,
                              textContent: item.info,
                            });
                            handleShow();
                          }}
                        >
                          Details
                        </Button>
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            checkStatus(item);
                          }}
                        >
                          Check
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </React.Fragment>
              ))}
            </Table>
          )}
        </Card.Body>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalContent.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea className="w-100">{modalContent.textContent}</textarea>
          </Modal.Body>
        </Modal>
      </Card>
    </>
  );
}
