import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Card, Badge, Button } from "react-bootstrap";
import { listChannels, filterByField } from "./services/api";

export default function Channels() {
  const navigate = useNavigate();

  const [channels, setChannels] = useState([]);
  const [subscriptionLength, setSubscriptionLength] = useState(0);
  const [donationsLength, setDonationsLength] = useState(0);

  async function loadDataBagdes() {
    const filteredSubscriptions = await filterByField(
      "subscriptions",
      "channelId",
      channels.channelId
    );
    const registerIDSubscription = [];
    filteredSubscriptions.data.forEach((subscription) => {
      if (!registerIDSubscription.includes(subscription.registerId)) {
        registerIDSubscription.push(subscription.registerId);
      }
    });

    setSubscriptionLength(registerIDSubscription.length);

    const filteredDonations = await filterByField(
      "donations",
      "channelId",
      channels.channelId
    );

    const completedDonations = filteredDonations.data.filter(
      (item) => item.donationStatus === "Completed"
    );

    setDonationsLength(completedDonations.length);
  }

  async function getChannelsList() {
    const data = await listChannels();

    setChannels(data.data);
  }

  function clickedSubscriptionsButton(channel) {
    navigate(`${channel.channelId}/subscriptions`);
    console.log(channel);
  }

  function clickedDonationsButton(channel) {
    navigate(`${channel.channelId}/donations`);
    console.log(channel);
  }

  function clickedDashboardButton(channel) {
    navigate(`${channel.channelId}/dashboard`);
    console.log(channel);
  }

  useEffect(() => {
    loadDataBagdes();
    getChannelsList();
  }, []);

  return (
    <Card className="mt-4 text-center">
      <Card.Body>
        <Card.Title className="fw-bold"> Channels </Card.Title>
        <Table bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>URL</th>
              <th>Country</th>
              <th>Motivation Code</th>
              <th>Subscriptions</th>
              <th>Donations</th>
              <th>Dashboard</th>
            </tr>
          </thead>
          {channels.map((item, channelId) => (
            <React.Fragment key={item.channelId}>
              <tbody>
                <tr>
                  <td>{item.name}</td>
                  <td>
                    <Card.Link href={item.url}>{item.url}</Card.Link>
                  </td>
                  <td>{item.country}</td>
                  <td>{item.codes}</td>
                  <td>
                    <Button
                      variant="outline-primary"
                      onClick={() => clickedSubscriptionsButton(item)}
                    >
                      Subscriptions{" "}
                      <Badge bg="secondary">{subscriptionLength}</Badge>
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="outline-primary"
                      onClick={() => clickedDonationsButton(item)}
                    >
                      Donations <Badge bg="secondary">{donationsLength}</Badge>
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="outline-primary"
                      onClick={() => clickedDashboardButton(item)}
                    >
                      Dashboard
                    </Button>
                  </td>
                </tr>
              </tbody>
            </React.Fragment>
          ))}
        </Table>
      </Card.Body>
    </Card>
  );
}
